import * as ReactDOM from 'react-dom'
import { svrExport } from '@sevenrooms/core/utils'
import { AppRoot } from '@sevenrooms/mgr-core'
import { AccessRuleBulkCopy, pollBulkCopyStatus } from './AccessRuleBulkCopy'

const render = (elem: HTMLElement, modalElem: HTMLElement, bulkCopyTextElem: HTMLElement) => {
  ReactDOM.render(
    <AppRoot>
      <AccessRuleBulkCopy
        modalElem={modalElem}
        bulkCopyTextElem={bulkCopyTextElem}
        onClose={() => elem && ReactDOM.unmountComponentAtNode(elem)}
      />
    </AppRoot>,
    elem
  )
}

export const startBulkCopyStatusPoll = (
  venueGroupId: string,
  $accessRuleBulkCopyBtn: HTMLElement,
  $accessRuleBulkCopyText: HTMLElement
) => {
  pollBulkCopyStatus(venueGroupId, $accessRuleBulkCopyBtn, $accessRuleBulkCopyText)
}

svrExport('MgrAccessRuleBulkCopy', 'render', render)
svrExport('MgrAccessRuleBulkCopy', 'startBulkCopyStatusPoll', startBulkCopyStatusPoll)
